'use client';
import Pagination from '@/components/Pagination/Pagination';
import SmPagination from '@/components/Pagination/SmPagination';
import { useRouter } from 'next/navigation';


interface IProps {
  page: number;
  total: number
}
export default function PaginationFooter(props: IProps) {
  const router = useRouter();
  const prefix = '/pdf';
  return (
    <section className='pagination-part z-50'>
      <Pagination curPage={props.page} 
        onChange={ (newPage) => router.push(`${prefix}/${newPage}`) } 
        total={props.total} 
        className="pt-[50px] bg-black text-white  pb-[60px] max-md:hidden "
        arrowStyle='w-[44px] h-[44px]'
        btnStyle='!w-[44px] !h-[44px]'
        activeStyle='!text-white'
        pageNumStyle='text-xl px-4 cursor-pointer text-white/45'
        ></Pagination>
      <SmPagination curPage={props.page} 
        onChange={ (newPage) => router.push(`${prefix}/${newPage}`) } 
        total={props.total} 
        className='font-medium  bg-black text-sm px-[58px] pt-[25px] pb-[70px] hidden max-md:flex'
        arrowStyle='w-[35px] h-[35px]'
        textStyle='text-[#191300] text-white text-[14px] leading-[19px]'
        inputStyle='bg-[#272727] rounded-[4px]'
        btnStyle='w-[35px] h-[35px] flex items-center justify-center rounded-full cursor-pointer cursor-pointer'
        ignoreBorderStyle
        lng='es'
        />
    </section>
  );
}
